import { Box, HStack, Link, Stack, Text } from "@chakra-ui/react";
import { T } from "~/libs/i18n";
import { PreventionLogo } from "~/components/prevention-logo.tsx";
import { AnimatedOutlet } from "~/components/animated-outlet.tsx";
import { $path } from "remix-routes";

export default function AuthLayout() {
  return (
    <Stack
      direction={{ base: "column", lg: "row" }}
      minH={{ base: "auto", lg: "100vh" }}
      sx={{
        backgroundColor: "#011f80",
        backgroundImage:
          "radial-gradient(#00145400 0%, #011f80aa 60%), url(/images/prevention-bg.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top right",
      }}
      overflowY={"hidden"}
      spacing={0}
    >
      <Stack
        minH={"100vh"}
        bg={"white"}
        overflow={{ base: "auto", lg: "visible" }}
      >
        <Stack
          bg={"white"}
          spacing={8}
          p={{ base: 4, md: 8, lg: 16 }}
          m={"0 auto"}
        >
          <PreventionLogo />
          <Stack
            w={"full"}
            maxW={{ base: "xs", md: "md" }}
            justifyContent={"center"}
          >
            <AnimatedOutlet />
          </Stack>
          <HStack>
            <Text>
              <T>Need help?</T>
            </Text>
            <Link href={$path("/contact-us")}>Contact us</Link>
          </HStack>
        </Stack>
      </Stack>
      <Stack bg={{ base: "white", lg: "transparent" }} position={"relative"}>
        <Stack position={"absolute"} top={"10%"}>
          <HStack h={"full"} ml={8}>
            <Box
              w={4}
              h={"120px"}
              bgGradient="linear(to-b, blue.200, green.400)"
            />
            <Text textStyle={"heading"} color={"white"}>
              <T>Workplace Injury Prevention</T>
            </Text>
          </HStack>
          <Text textStyle={"body"} color={"white"} ml={12}>
            <T>Helping industrial athletes stay healthy</T>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
}
